.insta-body {
      font-family: 'Crimson Text', serif;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;

      h4 {
            text-transform: uppercase;
      }
      p {
            margin-bottom: 40px;
      }
      a {
            border: 2px solid black;
            color: black;
            line-height: 50px;
            text-decoration: none;

            padding: 15px 20px;
      }
}