.main-collection {
	background-color: $color-background;
	padding: 0;
	margin: 0;
}

.title {
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;

	.c-title {
		line-height: 2.8125rem;
		font-weight: 400;
		position: relative;
		padding-top: 40px;
		margin-bottom: 40px;
		padding-bottom: 20px;
		width: 100%;
		text-align: center;
		color: $color-black;

		font-family: PlayfairDisplay-Bold;
		font-size: 32px;
		text-transform: uppercase;

		&::after {
			position: absolute;
			bottom: 0;
			left: calc(50% - 118px);
			background: url('../../svg/underline-red.svg') no-repeat 0 0;
			width: 237px;
			height: 8px;
			content: '';
		}
	}
}

.trending-image {
	width: 100%;
	height: 450px;
	margin: auto;

	-webkit-transition: none;
	transition: none;
	-webkit-box-shadow: 10px 10px 10px rgba(144, 143, 143, 0.15);
	box-shadow: 10px 10px 10px rgba(144, 143, 143, 0.15);
	background: $color-white;
	width: 100%;

	img {
		width: 100%;
		height: 100%;
		transition: all 500ms ease-in;
		object-fit: cover;
		filter: grayscale(0);
		will-change: filter;
		overflow: hidden;
	}

	&:hover {
		img {
			transform: scale(1.05);
			overflow: hidden;
		}
	}
}

.collection-wrapper {
	cursor: pointer;

	.card-wrapper {
		-webkit-transition: none;
		transition: none;
		margin: 0 auto auto;
		-webkit-box-shadow: 10px 10px 10px rgba(144, 143, 143, 0.15);
		box-shadow: 10px 10px 10px rgba(144, 143, 143, 0.15);
		background: $color-white;
		width: 100%;

		a {
			text-decoration: none;
		}

		.card-wrapper-image {
			position: relative;
			background: $color-secondary;
			width: 100%;
			height: 450px;
			margin: 0;
			// transition: all 300ms ease-in;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				transition: all 500ms ease-in;
				object-fit: cover;
				filter: grayscale(0);
				will-change: filter;
				overflow: hidden;
			}
		}

		.card-wrapper-content {
			transition-timing-function: ease;
			transition-duration: 0.4s;
			transition-property: background;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			margin-bottom: auto;
			background: $color-white;
			padding: 10px 60px;
			height: 50px;

			.card-wrapper-title {
				transition-timing-function: ease;
				transition-duration: 0.4s;
				transition-property: opacity;
				// font-weight: 600;
				text-align: center;

				font-size: 1.15rem;
				line-height: 20px;
				$color-checking: #fad6b6;
				$color-text-hover: #2b2520;
				margin: 0 auto;
				letter-spacing: 1px;
				font-style: italic;
				// text-transform: uppercase;
				color: $color-black;
				// font-family: $font;
			}
		}

		&:hover {
			.card-wrapper-content {
				background-color: $color-checking;
			}

			.card-wrapper-title {
				color: $color-text-hover;
			}

			.card-wrapper-image {
				img {
					transform: scale(1.05);
					overflow: hidden;
				}
			}
		}
	}

	& > :nth-child(2),
	& > :nth-child(5),
	& > :nth-child(8) {
		margin-top: 40px;
	}

	@media (max-width: 991.98px) {
		& > :nth-child(2),
		& > :nth-child(5),
		& > :nth-child(8) {
			margin-top: 0px;
		}
	}
}
