#about-us {

  .about-us-title {
    text-align: center;
    font-family: $font-dancing-script;
    font-size: 40px;
    padding-bottom: 20px;
  }

  .about-kaavya {
    padding: 0px 20px;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      border: 0px;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  p {
    text-align: justify;
    padding: 10px 0px;
    font-size: 14px;
  }

  .about-kaavya-content {
    text-align: center;
    margin: 20px 0px;

    h2 {
      font-size: 14px;
      font-weight: 700;
    }
  }

  // .about-kaavya-content

  .about-owner {
    background-color: $color-highlight;
    padding: 26px 0px;

    .about-designer-info {
      padding: 0px 20px;
    }
  }

  .slogan {
    font-family: $font-architects-daughter;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px 0px;
    margin: 10px 0px;

    h1 {
      font-size: 28px;
      text-align: center;
    }
  }

  .about-how-we-work {
    padding: 26px 0px;

    .about-how-we-work-info {
      padding: 0px 20px;
    }
  }

  .how-we-design-grid-container {
    display: flex;
    // grid-template-columns: auto auto;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: #2196F3;

    .how-we-design-grid-item {
      background-color: rgba(255, 255, 255, 0.8);
      // border: 1px solid rgba(0, 0, 0, 0.8);
      padding: 20px 0px;
      font-size: 30px;
      text-align: center;
      flex: 0 50%;

      .item-description {
        padding: 10px;

        p {
          text-align: center;
          letter-spacing: 0.2px;
        }
      }

      // img {
      //   height: 30%;
      //   width: 30%;
      // }
    }
  }

}


// for more desktop screen
@media (min-width: 768px) {
  #about-us {

    .about-owner {
      display: grid;
      grid-template-columns: 1fr 1fr; // fr is fraction which is for inner divisions
      grid-gap: 20px;
    }

    .about-how-we-work {
      display: grid;
      grid-template-columns: 1fr 1fr; // fr is fraction which is for inner divisions
      grid-gap: 20px;
    }

    .image-tag-container {

      /* Centered text */
      .image-centered-text {
        font-size: 60px;
      }
    }

    // since all p tag need to have 18 px font size
    p {
      font-size: 18px;
    }

    .about-kaavya-content {
      h2 {
        font-size: 26px;
      }
    }

    .slogan {
      h1 {
        font-size: 40px;
      }
    }

    .slogan-description {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }


    .how-we-design-grid-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  
      .how-we-design-grid-item {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px 0px;
        font-size: 30px;
        text-align: center;
        flex: 0 20%;
  
        .item-description {
          padding: 10px;
  
          p {
            text-align: center;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}