*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	margin: 0;
	padding: 0;
}

body {
	font-family: $font-montserrat;
	font-weight: 400;
	line-height: 1.7;
	color: color(bodyColor);
	box-sizing: border-box;
}
