#contact {

    .container {
        .contact-us-title {
            text-align: center;
            font-family: $font-dancing-script;
            font-size: 40px;
            padding-bottom: 20px;
        }

        .contact-us-description {
            text-align: center;
            margin: 20px 10%;
            
        }

        .contact-us-contact-detail {

            padding: 6px;

            h4 {
                color: $color-secondary;
                font-size: 18px;
            }

            // for call us and email
            a {
                color: $color-secondary;
                font-size: 14px;
            }
            
            // for location
            p {
                color: $color-secondary;
                font-size: 14px;
            }
        }
    }

    .card:hover i,#contact .card:hover h4 {
        color: #87d37c;
    }
}
