//ribbon

.ribbon {
	position: relative;
	text-transform: uppercase;
	color: white;
	z-index: 1;
	position: absolute;
	right: 15px;
	top: -5px;
}

.ribbon5 {
	display: block;
	width: calc(100% + 20px);
	height: 30px;
	line-height: 30px;
	text-align: center;
	margin-left: -10px;
	padding: 0 10px;
	font-weight: 600;
	font-size: 14px;
	margin-right: -10px;
	background: #ab300a;
	position: relative;
	top: 20px;
}
.ribbon5:after {
	content: '';
	position: absolute;
}

.ribbon5:after {
	height: 0;
	width: 0;
	right: 0;
	bottom: -10px;
	border-top: 10px solid #c0370d;
	border-right: 10px solid transparent;
}

//loader

.sweet-loading {
	position: absolute;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -30%);
}

//error
.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid $color-primary;
	border-radius: 0.25rem;
	background: $color-primary;
	margin: 1rem;

	.alert-text {
		font-size: 14px;
		color: #fff;
	}
}

//navbar
.navbar {
	background: $color-white;
	border-bottom: 3px solid $color-checking-dark;
}

.navbar-dark .navbar-toggler {
	border-color: transparent;
	color: $color-black;
	background: $color-white;
	padding: 0.1rem 0.4rem;

	&:focus {
		outline: transparent;
	}

	.navbar-toggler-icon {
		background-image: url('https://mdbootstrap.com/img/svg/hamburger7.svg?color=000000');
	}

}

.navbar-item {
	padding: 0px 10px;
}

.nav-item {
	padding-right: 20px;
	color: $color-black;

	.nav-link {
		color: rgba($color-black, 0.6) !important;
	}

	.dropdown-menu-items {
		border-style: none;
	}

	li {
		padding-left: 10px;

		a {
			color: black;
		}
	}
}

.sticky-top {
	z-index: 999;
}

//pagenotfound
.pageNotFound {
	height: 90vh;
	width: 100vw;
	background: #f3f3f3;

	.pagewrap {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-transform: uppercase;
		color: $color-primary;

		h1 {
			font-weight: 600;
			font-size: 60px;
		}
	}
}

