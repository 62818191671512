
//footer
.footer {
	background: $color-footer-background;
    padding: 15px 0;
    
    .container {
        .get-updates {
			margin-bottom: 20px;

            a {
                // font-size: 20px;
                padding-right: 6px;
			}
			img {
				// width:"36px" height="36px"
				width: 36px;
				height: 36px;
			}
        }
    }

	&-info {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0;
			color: rgba($color-black, 0.6);
			font-size: 15px;
			line-height: 38px;

			span {
				font-weight: bold;
			}
		}

		.madeby {
			color: rgba($color-black, 0.6);
			font-size: 15px;
			a {
				font-weight: bold;
				color: rgba($color-black, 0.6);

				&:hover {
					color: $color-primary;
					text-decoration: none;
				}
			}

			span {
				color: red;
				font-size: 20px;
			}
		}
	}
}