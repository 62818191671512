.carousel-item {
	height: 100vh;

	img {
		height: 90.5vh;
		object-fit: cover;
	}
}

.carousel-caption {
	position: absolute;
	top: 55%;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	width: 100vw;
	// text-align: center;

	h3 {
		font-family: PlayfairDisplay-Bold;
		font-size: 60px;
		line-height: 1.1;
		padding-top: 15px;
		padding-bottom: 30px;
		text-transform: uppercase;
	}

	span {
		font-family: Poppins-Regular;
		font-size: 28px;
		// color: #333;
		line-height: 1.2857;
	}

	a {
		border-radius: 25px;
		background-color: $color-primary;
		min-width: 161px;
		height: 46px;
		font-family: Poppins-Medium;
		font-size: 15px;
		line-height: 1.466667;
		text-transform: uppercase;
		color: #fff;

		padding: 15px 45px;
		transition: all 300ms ease-out;

		&:hover {
			background-color: #333;
			text-decoration: none;
		}
	}

	@media (max-width: 576px) {
		a {
			font-size: 12px;
			padding: 14px 35px;
		}
		h3 {
			font-size: 35px;
		}

		span {
			font-size: 20px;
		}
	}
}
