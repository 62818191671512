@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

$color-primary: #d52316;
$color-secondary: #3a112f;
$color-highlight: #f0f7f2;
$color-white: #ffffff;
$color-black: #000000;
$color-gray-light: #999;
$color-gray-dark: #333;
$color-background: #ffffff;
$color-footer-background: #e0dada;
$color-checking: #ded2c5;
$color-checking-dark: #a88f74;
$color-text-hover: #2b2520;

$font: 'Nunito Sans', sans-serif;
$font-open-sans: 'Open Sans', sans-serif; 
$font-montserrat: 'Montserrat', sans-serif;
$font-roboto-mono: 'Roboto Mono', monospace;
// $font-open-sans-condensed: 'Open Sans Condensed', sans-serif;
// $font-source-code-pro: 'Source Code Pro', monospace;
// $font-inconsolata: 'Inconsolata', monospace;
// $font-bitter: 'Bitter', serif;
$font-dancing-script: 'Dancing Script', cursive;
$font-architects-daughter: 'Architects Daughter', cursive;
// $font-indie-flower: 'Indie Flower', cursive;
